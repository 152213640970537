<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'newCustomer'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">New Customer</h5>
            <a class="close" @click="$store.dispatch('modalClose')" title="Close">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-5 mt-0 mb-1">
                            <input type="file" @change="base64Image" id="customer_image" style="display:none;">
                            <label for="customer_image" class="popup-img-container">
                              <span>Browse <br> Image</span>
                              <div class="popup_img">
                                <img class="popup_img" :src="formData.image?formData.image:'/assets/img/logosmall.png'" alt="">
                                <div id="change_img">Change <br> Image</div>
                              </div>
                            </label>
                          </div>
                          <div class="col-md-7 pl-0 pd-t-30 pb-0">
                            <div class="row">
                              <div class="col-md-5 mr-0 pr-0">Customer ID</div>
                              <div class="col-md-7 ml-0 pl-0">
                                <input class="form-control" id="customer_id" placeholder="id" v-model="formData.customer_id" disabled />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Customer Name *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input type="text" class="form-control" id="customer_name" v-model="formData.name" placeholder="Customer Name"  required/>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Address</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input type="text" class="form-control" id="customer_address" v-model="formData.address" placeholder="Address" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">PAN / VAT No.</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input type="number" class="form-control" id="customer_pan_vat_no"
                              v-model="formData.pan_vat_no" placeholder="PAN No." @keyup="checkPanValidation" />
                              <span class="unique-message" v-if="pan_error_msg == true">Pan number must be 9 digits </span>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                    <div class="group mb-3" v-if="authData.general_setting.enable_vehicle_detail==1">
                      <label class="group-label">Vehicle Detail</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Engine Number</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              v-model="formData.engine_no"
                              class="form-control"
                              id="customer_engine_no"
                              placeholder="Engine No."
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Chassis Number</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              v-model="formData.chasis_no"
                              class="form-control"
                              id="customer_chasis_no"
                              placeholder="Chassis No."
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Model Number</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              v-model="formData.model_no"
                              class="form-control"
                              id="customer_model_no"
                              placeholder="Model No."
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Vehicle Number</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              v-model="formData.vehicle_no"
                              class="form-control"
                              id="customer_vehicle_no"
                              placeholder="Vehicle No."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">Contact Detail</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Phone No</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input type="text" placeholder="Phone No." v-model="formData.phone" 
                            class="form-control" id="customer_phone_no" maxlength="25"/>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Email</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input type="email" name="bank" placeholder="abc@domain.com" v-model="formData.email" class="form-control" id="customer_email" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Website</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="url"
                              placeholder="https://google.com/"
                              class="form-control"
                              id="customer_website"
                              v-model="formData.website"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div class="col-md-6 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Contact Person</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Name</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              v-model="formData.contact_person_name"
                              class="form-control"
                              id="customer_contact_person_name"
                              placeholder="Name"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Designation</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              v-model="formData.contact_person_designation"
                              class="form-control"
                              id="customer_designation"
                              placeholder="Director"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Contact No.</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              v-model="formData.contact_person_phone"
                              class="form-control"
                              id="customer_contact_no"
                              placeholder="98xxxxxxxx"
                              maxlength="25"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Email</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="email"
                              v-model="formData.contact_person_email"
                              class="form-control"
                              id="customer_email"
                              placeholder="abc@domain.com"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group mb-3">
                      <label class="group-label">Amount Detail</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Opening Balance</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="number"
                              step="any"
                              v-model="formData.opening_due"
                              class="form-control"
                              id="customer_opening_balance"
                              placeholder="NPR 0.00"
                              value="0"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">Description</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-12">
                            <textarea
                              class="form-control"
                              id="customer_description"
                              type="text"
                              v-model="formData.description"
                              style="height: 2.9rem;"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary" :disabled="disableSubmitButton"><span v-if="pan_error_msg==true">Submit</span><span v-else>{{ disableSubmitButton ? 'Submitting....' : 'Submit'}}</span></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import Services from "./Services/Services";
  export default {
    data() {
      return {
        formData: {
          customer_id:null,
          image:null,
          name:'',
          phone:null,
          address:null,
          email:'',
          pan_vat_no:'',
          website:'',
          contact_person_name:'',
          contact_person_designation:'',
          contact_person_phone:'',
          contact_person_email:'',
          opening_due:null,
          description:null,
          vehicle_no:null,
          engine_no:null,
          model_no:null,
          chasis_no:null,
        },
        disableSubmitButton:false,
        pan_error_msg:false,
      };
    },
    props:["customerData"],
    computed: {
      ...mapGetters([
        "processing",
        "authData",
        "eventMessage",
        "dataLists",
        'dataLists9',
        "dataId",
        "modalId",
        "modalMode",
      ]),
      ...mapGetters("customers",["customerLists"])
    },
    methods: {
      base64Image(e) {
        this.formData.image = null;
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (e) => {
          this.formData.image = e.target.result;
        };
      },
      clearData(){
          this.formData.customer_id=null;
          this.formData.image=null;
          this.formData.name=null;
          this.formData.phone=null;
          this.formData.address=null;
          this.formData.email=null;
          this.formData.pan_vat_no=null;
          this.formData.website=null;
          this.formData.contact_person_name=null;
          this.formData.contact_person_designation=null;
          this.formData.contact_person_phone=null;
          this.formData.contact_person_email=null;
          this.formData.opening_due=null;
          this.formData.description=null
          this.formData.model_no=null;
          this.formData.chasis_no=null;
          this.formData.vehicle_no=null;
          this.formData.engine_no=null;
      },
      checkPanValidation(){
         if(!isNaN(this.formData.pan_vat_no) && this.formData.pan_vat_no != '' && this.formData.pan_vat_no != null){
            if(this.formData.pan_vat_no.length != 9 || this.formData.pan_vat_no < 0){
                this.disableSubmitButton = true;
                this.pan_error_msg = true;
            }else{
              this.disableSubmitButton = false;
              this.pan_error_msg = false;
            }
          }else{
            this.disableSubmitButton = false;
            this.pan_error_msg = false;
          }
      },
      submit() {
        if (this.modalMode == "create") {
          this.disableSubmitButton = true;
          this.loading = true; 
          Services.createNewCustomer(this.formData).then(res=>{
            //using the customer data in sales modal. Dont remove this line
            this.$store.commit("customers/setCustomerLists",res.data.data.data);
            this.$emit('store-customer');
            this.$store.dispatch("modalClose");
            this.setNotification(res.data.success_message);
        }).catch(err=>{
            this.error=false;
            console.log(err);
            if(err.response.status == 422){
                let error = Object.values(err.response.data.errors).flat();
                this.setNotification(error);
             }else{
                console.log(err);
             }
        }).finally(()=>{
          this.loading = false;
          this.disableSubmitButton =false;
        })
        }
      },
      setNotification(msg){
        console.log(msg)
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
    },
    watch: {
      modalId() {
        if(this.modalId == 'newCustomer'){
          this.disableSubmitButton = false;
          this.$store.commit("getData9", `api/customers/create`);
        }
      },
      dataLists9(value){
        if(this.modalMode == "create"){
          if (value.length != 0) {
          this.formData.customer_id = `${value.customer_id}`;
        }
        }
      },
      modalMode(value) {
        if (value == "create") {
           this.clearData();
        }
      },
    },
  };
</script>
<style scoped>
  .unique-message{
    color: #DB4437;
  }
</style>